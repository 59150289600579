import { computed } from 'vue'
import { useQueryUser } from '@/api/useUsers'
import { getUserFullName } from '@/api/utils/string'
import { getStaticUserData } from '@/api/utils/user'

/**
 * Composable for easy access to the current user
 */
export const useCurrentUser = () => {
  const userData = getStaticUserData()
  const { data: currentUser } = useQueryUser(userData.userId)

  return {
    currentUser,
    currentUserFullName: computed(() =>
      currentUser.value ? getUserFullName(currentUser.value) : null
    ),
  }
}
